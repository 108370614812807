var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"visible":_vm.visible,"title":"编辑点检保养计划","confirmLoading":_vm.confirmLoading,"destroyOnClose":true,"maskClosable":false,"width":680},on:{"cancel":_vm.handleCancel,"ok":_vm.handleConfirm}},[_c('a-form',{attrs:{"form":_vm.dataForm,"label-col":{ span: 6 },"wrapper-col":{ span: 16 }}},[_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"编号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'number',
                {
                  initialValue: _vm.item.number,
                  rules: [
                    { required: true, message: '请输入编号' },
                    { max: 32, message: '超出最大长度(32)' },
                  ],
                },
              ]),expression:"[\n                'number',\n                {\n                  initialValue: item.number,\n                  rules: [\n                    { required: true, message: '请输入编号' },\n                    { max: 32, message: '超出最大长度(32)' },\n                  ],\n                },\n              ]"}],attrs:{"allowClear":true}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'name',
                {
                  initialValue: _vm.item.name,
                  rules: [
                    { required: true, message: '请输入名称' },
                    { max: 64, message: '超出最大长度(64)' },
                  ],
                },
              ]),expression:"[\n                'name',\n                {\n                  initialValue: item.name,\n                  rules: [\n                    { required: true, message: '请输入名称' },\n                    { max: 64, message: '超出最大长度(64)' },\n                  ],\n                },\n              ]"}],attrs:{"allowClear":true}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"频率"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'frequency',
                { initialValue: _vm.item.frequency, rules: [{ required: true, message: '请选择类型' }] },
              ]),expression:"[\n                'frequency',\n                { initialValue: item.frequency, rules: [{ required: true, message: '请选择类型' }] },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"precision":0,"min":0}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"频率单位"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'frequency_unit',
                {
                  initialValue: _vm.item.frequency_unit,
                  rules: [{ required: true, message: '请选择频率单位' }],
                },
              ]),expression:"[\n                'frequency_unit',\n                {\n                  initialValue: item.frequency_unit,\n                  rules: [{ required: true, message: '请选择频率单位' }],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"allowClear":true}},[_c('a-select-option',{attrs:{"value":"hour"}},[_vm._v("小时")]),_c('a-select-option',{attrs:{"value":"day"}},[_vm._v("天")]),_c('a-select-option',{attrs:{"value":"week"}},[_vm._v("周")]),_c('a-select-option',{attrs:{"value":"month"}},[_vm._v("月")]),_c('a-select-option',{attrs:{"value":"quarter"}},[_vm._v("季度")]),_c('a-select-option',{attrs:{"value":"year"}},[_vm._v("年")])],1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"开始时间"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['start_date', { initialValue: _vm.item.start_date }]),expression:"['start_date', { initialValue: item.start_date }]"}],staticStyle:{"width":"100%"},attrs:{"valueFormat":"YYYY-MM-DD","allowClear":true}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"结束日期"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['end_date', { initialValue: _vm.item.end_date }]),expression:"['end_date', { initialValue: item.end_date }]"}],staticStyle:{"width":"100%"},attrs:{"valueFormat":"YYYY-MM-DD","allowClear":true}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'type',
                {
                  initialValue: _vm.item.type,
                  rules: [{ required: true, message: '请选择类型' }],
                },
              ]),expression:"[\n                'type',\n                {\n                  initialValue: item.type,\n                  rules: [{ required: true, message: '请选择类型' }],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"allowClear":true},on:{"change":_vm.onChangeType}},[_c('a-select-option',{attrs:{"value":"inspection"}},[_vm._v("设备点检")]),_c('a-select-option',{attrs:{"value":"maintenance"}},[_vm._v("设备保养")])],1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"备注"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['remark', { initialValue: _vm.item.remark, rules: [{ max: 256, message: '超出最大长度(256)' }] }]),expression:"['remark', { initialValue: item.remark, rules: [{ max: 256, message: '超出最大长度(256)' }] }]"}],attrs:{"allowClear":true}})],1)],1)],1)],1),_c('a-tabs',[_c('a-tab-pane',{key:"equipment",attrs:{"tab":"设备清单","forceRender":""}},[_c('EquipmentPane',{model:{value:(_vm.equipmentItems),callback:function ($$v) {_vm.equipmentItems=$$v},expression:"equipmentItems"}})],1),(_vm.planType === 'inspection')?_c('a-tab-pane',{key:"inspection",attrs:{"tab":"点检项目","forceRender":""}},[_c('InspectionPane',{model:{value:(_vm.inspectionItems),callback:function ($$v) {_vm.inspectionItems=$$v},expression:"inspectionItems"}})],1):_vm._e(),(_vm.planType === 'maintenance')?_c('a-tab-pane',{key:"maintenance",attrs:{"tab":"保养项目","forceRender":""}},[_c('MaintenancePane',{model:{value:(_vm.maintenanceItems),callback:function ($$v) {_vm.maintenanceItems=$$v},expression:"maintenanceItems"}})],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }